.container2 {
  width: 100%;
  height: 100%;
  margin: auto;
  padding-top: 20px;
  padding-left: 20px;
}

@media (max-width: 768px) {
  .container2 {
    padding-left: 0px;
  }

}

