.container {
  height: 100vh;
  background-color: white;
  width: 100%;
  overflow-x: hidden;
}


.overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255,255,255,0.4);
  z-index: 2;
  cursor: pointer;
}
