@media (max-width: 768px) {
    .text-sm{
        font-size: 2rem;
    }
    .flex-col{
        display: flex;
        flex-direction: column;
    }
    .w-full{
        width: 100% !important;
    }
    .margin-top{
        margin-top: 2rem !important;
    }
    .w-90{
        width: 90% !important;
    }
    #flex-col{
        display: flex;
        flex-direction: column;
    }
    #w-90{
        width: 90% !important;
    }
    #margin-top{
        margin-top: 2rem !important;
    }
    #h-90{
        height: 90% !important;
    }
    .w-45{
        width: 45% !important;
    }
    .margin-top-sm{
        margin-top: 10px !important;
    }
    .btn{
        position: relative;
        bottom: 30px;
    }
    .h2{
        position: relative;
        top: 10px;
    }
}