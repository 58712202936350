@import "../../../config/variables";

.boxShadow {
  box-shadow: 0px 4px 25px 0px #0000001F;
  border-color: #E7E7E7;
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
}

.vehicleRow {
  width: 80%;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  padding: 10px;
}

.dateWrapper {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 20px;
}

.indiDateWrapper {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.vehRowLabel {
  font-size: 16px;
  color: $darkTextColor;
  width: 150px;
}

.vehRowValue {
  font-size: 16px;
  color: $secondaryTextColor;
}

.vehRowBtnWrapper {
  width: 200px;
  display: flex;
}

.avaLabel {
  font-size: 16px;
  color: $secondaryTextColor;
  display: none;
}

.vehRowMake {
  color: $darkTextColor;
  font-size: 24px;
  margin-right: 10px;
}

.priceTxt {
  color: $darkTextColor;
  font-size: 28px;
}


@media (max-width: 768px) {
  .dateWrapper {
    flex-direction: column;
  }

  .vehRowMake {
    font-size: 16px;
  }

  .avaLabel {
    font-size: 12px;
    display: flex;
  }

  .indiDateWrapper {
    width: 95%;
  }

  .vehicleRow {
    width: 90%;
    margin-top: 10px;
    padding: 4px;
  }

  .vehRowLabel {
    font-size: 12px;
    width: 40%;
  }

  .priceTxt {
    font-size: 18px;
  }

  .vehRowValue {
    font-size: 12px;
  }

  .vehRowBtnWrapper {
    display: none;
  }
}