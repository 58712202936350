@media (max-width: 768px) {
    .infoMainDiv {
        display: flex;
        flex-direction: column-reverse !important;
        justify-content: initial !important;
        width: 100vw !important;
        /* background-color: red; */
        height: auto !important;
    }

    .infoMainCarCard {
        width: 100vw !important;
        height: 90vh !important;
        padding: 0 !important;
        mrgin-right: 5px;
        /* background-color: gray; */
        /* margin-top: 10rem !important; */
    }

    .carCardContent {

        height: 100% !important;
        /* margin-top: 10rem; */
    }

    .carContainerDiv {
        width: 100% !important;
        height: 95% !important;
        /* margin-top: 20rem !important; */
        /* display: none !important; */
    }

    .infoMainDetailsCard {
        width: 100vw !important;
        height: 60rem !important;
        /* margin-top: 50px; */
    }

    .userInfoStep1Container {
        width: 95% !important;
        margin: auto;
    }

    .form {
        /* background-color: red; */
        position: relative;
        bottom: 50px;
    }

    .dialogReservedMain {
        right: 100px !important;
        max-height: 40vh !important;
        min-height: 40vh !important;
    }

    .dialogReserved {
        min-width: 80vw !important;
    }

    .dialogFirst {
        width: 100vw !important;
    }
}
