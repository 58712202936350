@import "../../../config/variables";

.container {
  padding: 20px;
  width: 50%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.imageContainer {
  width: 80%;
  height: 300px;
}

.carText {
  font-size: 24px;
  width: 100%;
  text-align: center;
  font-weight: bold;
  color: $themeLight;
}

.dateText {
  font-size: 20px;
  color: $theme;
}

.detailsLabel {
  font-size: 18px;
  color: $secondaryTextColor;
  width: 180px;
}

.detailsValue {
  font-size: 18px;
  color: $darkTextColor;
}

.cardDetailsWrapper {
  width: 60%;
}

.invoiceDetailsWrapper {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  width: 80%;
}

@media only screen and (max-width: 650px) {
  .container {
    width: 90%;
    padding: 10px;
  }

  .invoiceDetailsWrapper {
    width: 100%
  }

  .cardDetailsWrapper {
    width: 100%;
  }

  .detailsLabel {
    font-size: 15px;
    width: 150px;
  }

  .detailsValue {
    font-size: 15px;
  }

  .imageContainer {
    width: 100%;
    height: 100%;
  }

  .carText {
    font-size: 20px;
  }

  .dateText {
    font-size: 14px;
  }
}