@import "../../../config/variables";

.wrapper {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.engineDetailsBox {
  width: 25%;
  border-right: 1px solid #d8d8d8;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  padding: 100px;
}

.calendarImgDiv {
  width: 70%;
  height: auto;
  margin-right: 20px;
}

.calendarDetailsDiv {
  width: 30%;
  padding: 20px;
  height: auto;
}

.boxShadow {
  box-shadow: 0px 4px 25px 0px #0000001F;
  border-color: #E7E7E7;
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
}

.detsWrapper {
  width: 46%;
  border-radius: 10px;
  background-color: rgba(253, 126, 20, .12);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  height: 60px;
}

.detsTxt {
  font-size: 14px;
  color: $darkTextColor;
}

.icon {
  height: 50px;
  width: 40px;
  margin-right: 6px;
}

.mileSecWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.detsLabel {
  color: $darkTextColor;
  font-size: 18px;
}

.detsValue {
  color: $secondaryTextColor;
  font-size: 16px;
}

.icon2 {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

.engineDetsWrapper {
  width: 100%;
  border: 1px solid $borderColor;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
  padding: 10px 0px;
  margin-top: 20px;
  height: 60px;
}

.makeTxt {
  color: $themeLight;
  font-size: 28px;
}

.priceTxt {
  color: $themeLight;
  font-size: 22px;
}

.priceDayTxt {
  font-size: 16px;
  display: flex;
  margin-top: 1%;
  color: $themeLight;
  margin-left: 4px;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  .priceDayTxt {
    font-size: 12px;
  }

  .makeTxt {
    font-size: 20px
  }

  .priceTxt {
    font-size: 18px;
  }

  .wrapper {
    padding-bottom: 200px;
  }

  .engineDetsWrapper {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .icon2 {
    height: 14px;
    width: 14px;
    margin-right: 10px;
  }

  .engineDetailsBox {
    padding: 4px;
  }

  .detsLabel {
    font-size: 13px;
  }

  .detsValue {
    font-size: 11px;
  }

  .mileSecWrapper {
    margin-top: 10px;
  }

  .icon {
    height: 35px;
    width: 30px;
  }

  .detsTxt {
    font-size: 12px
  }

  .detsWrapper {
    height: 50px
  }

  .calendarImgDiv {
    width: 100%;
    margin-top: 140px;
    margin-right: 0px;
  }

  .calendarDetailsDiv {
    width: 95%;
    padding: 10px;
  }
}