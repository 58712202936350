@media (max-width:768px){
    .calendar-main-div{
        display: flex !important;
        flex-direction: column !important;
        gap: 10px;
    }
    .calendar-img-div{
        width: 95vw !important;
        height: 35% !important;
    }
    .calendar-img{
        width: 100%;
        height: 100% !important;
    }
    .calendar-paper{
        width: 85% !important;
    }
}
