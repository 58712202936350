$theme: #23395D;
$buttonTheme: #5481CB;
$themeLight: #5481CB;
$tabTheme: #5481CB;
$backgroundColor: #ffffff;
$primaryTextColor: #5481CB;
$secondaryTextColor: #B0B0B0;
$tertiaryTextColor: #ffffff;
$darkTextColor: #23395D;
$contentBackgroundColor: #E7E7E7;
$borderColor: #d8d8d8;
